.mastersheet-table th,
.mastersheet-table td {
    white-space: nowrap;
    padding: 12px;
    text-align: center;
}

.mastersheet-row {
    background-color: #f1f3f5 !important;
}

.mastersheet-table .hovered-row {
    background-color: #0a7ef3 !important;
    color: white;
}

.mastersheet-table thead th {
    background-color: #004085;
    color: white;
    font-weight: bold;
}

.table-hover>tbody>tr:hover>* {
    --bs-table-color-state: var(--bs-table-hover-color);
    --bs-table-bg-state:  none;
}

.mastersheet-table.table-hover > tbody > tr:hover > * {
    --bs-table-bg-state: none !important;
}