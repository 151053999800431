.calendarForm-form {
  border: 1px solid #ddd;
  border-radius: 10px;
  margin: 50px 0;
  box-shadow: 0 0 25px 0 rgba(0, 0, 0, 5%);
}

.calendarForm-form h2 {
  color: #0d568e;
}

.nmbr input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}