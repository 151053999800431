.App {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
}

.conatiner {
  width: 50%;
  border: 2px solid #707070;
  border-radius: 15px;
  padding: 10px;
  background-color: bisque;
  height: 250px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.text {
  font-size: larger;
  color: '#000'
}

.btnConatiner {
  width: 40%;
  display: flex;
  justify-content: space-between;
}

.cancelBtn {
  background-color: transparent;
  border: 1px solid #707070;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 80px;
  padding:10px 15px;
  cursor: pointer;
}

.okBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #707070;
  border-radius: 10px;
  background-color: rgb(255, 0, 0);
  min-width: 80px;
  padding:10px 15px;
  cursor: pointer;
  color: #fff
}