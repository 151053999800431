/* Blog */
.cmn-hero {
    background-repeat: no-repeat;
    background-size: cover;
    padding: 153px 0;
}

.cmn-hero-inner h2 {
    font-size: 40px;
}

ul.breadcrums {
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
}

ul.breadcrums li a, ul.breadcrums li {
    font-size: 16px;
    font-weight: 700;
}

ul.breadcrums li a {
    text-decoration: none;
    color: #ff6128;
    display: flex;
    align-items: center;
}

ul.breadcrums li {
    color: #8A909A;
    margin-right: 5px;
}

.col-med-3 {
    width: 33.3%;
    padding: 0 15px;
}

.blg-ls-img img {
    width: 100%;
    display: block;
}
.blg-ls {
    border: 1px solid #DDDDDD;
    border-radius: 10px;
    transition: all 0.3s;
    background-color: #fff;
}

.blg-ls-content {
    padding: 25px 30px;
}

.auth-m {
    color: #8A909A;
    font-size: 12px;
    font-weight: 700;
}

.blg-ls-content h2 {
    font-size: 16px;
    margin: 8px 0;
}

.blg-ls-content p {
    font-size: 14px;
    line-height: normal;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    white-space: normal;
    overflow: hidden;
    text-overflow: ellipsis;
}

.rdo-more {
    color: #FF6128;
    text-decoration: none;
    font-size: 14px;
}

.blog-listing .col-med-3 {
    margin: 15px 0;
}

.blg-ls:hover {box-shadow: 2px 5px 5px 0 rgb(0 0 0 / 9%);}
.pagi_ul {
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.pagi_ul li a {
    display: inline-block;
    width: 40px;
    height: 40px;
    background-color: #D9D9D9;
    line-height: 40px;
    text-align: center;
    border-radius: 50px;
    color: #000;
    font-size: 16px;
    text-decoration: none;
    margin: 0 5px;
}

.pagination {
    margin: 15px 0 0;
}
.pagi_ul li.active a {
    background-color: #0C518A;
    color: #fff;
}
/* End Blog */

/* Blog Details */
.blg-dtl-img {
    height: 390px;
    /* width: 45px; */
    border-radius: 10px;
    background-size: cover;
    background-position: center;
    object-fit: cover;
    margin: 0 0 30px;
}

.blog-details h2 {
    font-size: 36px;
}
.breadcrums-main {
    padding: 20px 0;
}

.blog-details {
    padding: 0 0 70px;
}
/* End Blog Details */
