.contact_conatiner {
    min-width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 100px;
}

.hero_banner {
    width: 50%;
    /* max-height: 640px;
    height: 640px; */
    /* background-color: #0e5492e6; */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-left: 160px;
}

.hero_banner h1 {
    color: #0E5492;
    font-family: 'Poppins', Medium;
    font-weight: 600;
    font-size: 60px;
    text-align: center;
    margin-bottom: 35px;
}

.hero_banner p {
    color: #686868c2;
    font-size: 24px;
    font-family: 'Poppins', SemiBold;
    text-align: center;
}

.form_container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50%;
    padding-block: 80px;
}

.form_container .form {
    width: 400px;
    padding-inline: 30px;
    padding-block: 40px;
    display: flex;
    flex-direction: column;
    background-color: #d8ecff;
    border-radius: 8px;
    height: auto;
    align-items: center;
    /* border: 1px solid #0E5492; */
    box-shadow: 0 0 20px 0 #e5f3ff;
}

.form_container .form h4 {
    color: #0E5492;
    font-size: 25px;
    line-height: 30px;
    font-family: 'Poppins', Regular;
    margin-bottom: 20px;
}

.form_container .form input {
    padding: 10px;
    border-radius: 10px;
    margin-block: 10px;
    border: 1px solid #E1E1E1;
    font-size: 18px;
    width: 100%;
}

.form_container .form input:focus-visible {
    outline: 0;
}

.form_submit_btn {
    height: 50px;
    width: 180px;
    background-color: #0E5492;
    color: #fff;
    border-radius: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-size: 18px;
    font-family: 'Poppins', SemiBold;
    margin-top: 25px;
}

::-webkit-input-placeholder {
    /* WebKit, Blink, Edge */
    color: #4b4b4b;
}

:-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    color: #4b4b4b;
    opacity: 1;
}

::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    color: #4b4b4b;
    opacity: 1;
}

:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #4b4b4b;
}

::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #4b4b4b;
}

::placeholder {
    /* Most modern browsers support this now. */
    color: #4b4b4b;
}


@media (max-width: 1399px) {
    .hero_banner h1 {
        font-weight: 600;
        font-size: 50px;
        margin-bottom: 35px;
    }

    .hero_banner p {
        font-size: 20px;
    }
}

@media (max-width: 570px) {
    .contact_conatiner {
        margin-top: 80px;
        flex-direction: column-reverse;
    }

    .hero_banner {
        width: 90%;
        padding-left: 0px;
    }

    .hero_banner h1 {
        font-weight: 700;
        font-size: 30px;
        margin-bottom: 15px;
    }

    .hero_banner p {
        font-size: 17px;
    }

    .form_container {
        width: 90%;
        padding-block: 50px;
    }

    .form_container .form {
        width: 350px;
        padding-inline: 20px;
        padding-block: 30px;
    }

    .form_container .form h4 {
        font-size: 22px;
        line-height: 30px;
    }

    .form_container .form input {
        padding: 8px;
        border-radius: 10px;
        margin-block: 10px;
        font-size: 16px;
    }

    .form_submit_btn {
        height: 40px;
        width: 140px;
        font-size: 17px;
    }
}

@media (max-width: 420px) {

    .hero_banner h1 {
        font-weight: 700;
        font-size: 25px;
        margin-bottom: 15px;
    }

    .hero_banner p {
        font-size: 15px;
    }

    .form_container .form h4 {
        font-size: 20px;
        line-height: 25px;
        margin-bottom: 5px;
    }

    .form_container .form input {
        font-size: 14px;
    }

    .form_submit_btn {
        height: 30px;
        width: 120px;
        font-size: 15px;
        margin-top: 10px;
    }

}