// 
// Page-title
// 

.page-title-box {
    padding-bottom: $grid-gutter-width;

    .breadcrumb {
        background-color: transparent;
        padding: 0;
    }
}
.doc-download-btn.btn-label {
    min-width: 100%;
}
.doc-download-btn.btn-label .label-icon{
    left: 50%;
    transform: translateX(-50%);
}