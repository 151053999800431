.cmnFrmstyle {
  background-color: #e7e7e7;
  @media (max-width: 1200px) {
    p {
      font-size: 12px;
    }
  }
  .wrapper {
    max-width: 60%;
    margin: 0 auto;
    width: 100%;
    background-color: #fff;
    @media (max-width: 1200px) {
      max-width: 90%;
    }

    .cmnfrmbg {
      position: relative;
      padding: 127px 120px;
      @media (max-width: 1200px) {
        padding: 57px 40px;
      }

      &::after,
      &::before {
        position: absolute;
        background-size: contain;
        background-repeat: no-repeat;
        content: "";
        left: 0;
        width: 100%;
      }

      &::after {
        bottom: 0px;
        height: 63px;
        background-image: url(../../../assets/images/btmBG.png);
      }

      &::before {
        height: 134px;
        top: 0;
        background-image: url(../../../assets/images/topBg.png);
      }
    }
  }

  .from2 {
    margin-top: 50px;

    p {
      text-align: left;
    }
  }

  .topsec {
    position: relative;
    text-align: center;

    .logoImg {
      max-width: 120px;
      position: absolute;
      left: -50px;
      top: -70px;
      @media (max-width: 1200px) {
        max-width: 60px;
        position: static;
        margin: 0 auto 10px;
        .propertyName h3 {
          font-size: 16px;
        }
      }

      img {
        width: 100%;
      }
    }
  }

  .detailProperty {
    text-align: center;
  }
}

.cmnfrmstyle {
  margin-top: 35px;

  form {
    max-width: 500px;
    margin: 0 auto;
  }

  .frmInner {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    @media (max-width:991px) {
      flex-wrap: wrap;
    }

    label {
      max-width: 180px;
      flex: 0 0 100%;
      margin: 0;
    @media (max-width:991px) {
      margin-bottom: 10px;
    }
    }
  }

  span {
    text-align: center;
    display: block;
    margin-bottom: 30px;
  }
}

.btmDetailFrm {
  position: relative;

  form {
    max-width: 600px;
  }

  span {
    margin-top: 30px;
  }
}

.descriptionFrm {
  form {
    max-width: 600px;
    margin-left: 0;
  }
}
@media (max-width: 1200px) {
  .propertyName h3 {
    font-size: 16px;
  }
  .detailProperty p {
    font-size: 12px;
  }
  .btn-group {
    margin-top: 10px;
  }
}
.tenantFrm {
  form {
    max-width: 100%;
    margin-left: 0;

    label {
      max-width: 100px !important;
      flex: 0 0 100%;
      margin: 0;
    }
  }
}

b {
  font-weight: 400 !important;
  font-size: 18px;
  margin-bottom: 10px;
  display: inline-block;
  color: #000;
}

strong {
  font-size: 19px;
  margin-bottom: 10px;
  display: inline-block;
  color: #000;
}

.midContent {
  strong {
    margin: 10px 0;
  }
}

.btmRightSec {
  position: absolute;
  right: 48px;

  td {
    border: 3px solid #000;
    width: 80px;
    text-align: center;
  }
}

.amountFiled {
  background-color: #fffb41 !important;
}

.residentFrm {
  form {
    max-width: 100%;

    .frmInner {
      margin-bottom: 10px;
    }
  }

  b {
    font-weight: 500 !important;
  }
}

.intercomFrm {
  form {
    max-width: 400px;
    margin: 0 auto;
    text-align: center;

    label {
      font-weight: 600;
      font-size: 20px;
      margin-bottom: 10px;
    }

    input {
      margin-bottom: 20px;
    }
  }
}

.propertyFrm4 {
  .detailProperty {
    strong {
      font-size: 22px;
    }

    p {
      font-size: 20px;
    }
  }

  .midImgText {
    gap: 50px;

    img {
      max-width: 50%;
    }
  }

  .imgInnerText {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}
