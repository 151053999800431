.cmnHding {
  font-size: 15px;
  position: relative;
  width: fit-content;
  padding: 0 20px 8px 0;

  &::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: #6e7379;
  }
}

form .updateForm {
  border-top: 1px solid #ced4da;
  text-align: center;

  button {
    padding: 10px 30px;
    font-weight: 600;
    letter-spacing: 1px;
  }
}

.modalInnner h5 {
  text-align: left;
  margin-bottom: 20px;
}

.modalBtn {
  flex-direction: row;

  >div {
    max-width: fit-content;
  }
}

.innnerFrmData {
  padding: 20px;

  input[type="file"] {
    width: 100%;
    padding: 0.47rem 0.75rem;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    appearance: none;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

    label {
      padding-top: 0;
      padding-bottom: 0;
    }
  }
}

.fileUpload {
  margin-top: 15px;
  border: 1px solid #ced4da;
  padding: 15px;
  border-radius: 10px;

  input {
    display: none;
  }

  label {
    display: inline-block;
    background-color: #0c518a;
    width: auto !important;
    border-radius: 10px;
    color: #fff;
    margin-left: 22px;
    margin-top: 15px !important;
    cursor: pointer;
    transition: all 0.3s linear;
    border: 1px solid #0c518a;

    &:hover {
      background-color: transparent;
      color: #0c518a;
    }
  }
}

.parking_page {
  .parkingFiling_sec {
    display: flex;
    flex-wrap: wrap;
    margin: 30px 0;
    padding: 20px;
    border: 1px solid #e9e9ef;
    border-radius: 5px;
    // justify-content: center;
    text-align: center;

    >div {
      flex: 0 0 100%;
      max-width: 130px;
      padding: 0 15px;
      margin-bottom: 30px;
      cursor: pointer;

      &.edit_parking>div {
        background-color: #e3e3e3;
      }

      >.inner_parking_sec {
        border: 1px solid #e9e9ef;
        padding: 10px;
        border-radius: 5px;
        position: relative;

        &:hover {
          border: 1px solid #1d4670;
        }

        .actioBtn {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 100%;
          height: 100%;
          background-color: rgba(140, 228, 231, 0.5);
          display: flex;
          justify-content: center;
          align-items: center;
          transition: all 0.3s linear;
          opacity: 0;
          pointer-events: none;
          cursor: pointer;

          button {
            background-color: #74788d;

            img {
              width: 20px;
            }
          }
        }

        &:hover {
          .actioBtn {
            opacity: 1;
            pointer-events: all;
          }
        }
      }

      img {
        max-width: 40px;
      }
    }
  }
}

select.form-control {
  appearance: auto;
}

.loading-background {
  z-index: 4000 !important;
}

.eyeIcon {
  img {
    width: 20px;
  }
}

.customDrop {
  button {
    display: flex;
    width: 100%;
    padding: 0.47rem 0.75rem 0.47rem 0.75rem;
    font-size: 0.875rem;
    font-weight: 400;
    background-color: #fff !important;
    border: 1px solid #ced4da !important;
    color: #495057 !important;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    box-shadow: none;
    justify-content: space-between;
    align-items: center;

    &:hover,
    &:focus {
      background-color: #fff !important;
      border: 1px solid #ced4da !important;
      color: #495057 !important;
    }
  }

  .dropdown-menu {
    width: 100%;
    padding: 15px;
    // box-shadow: none;
  }

  .dropdown-menu>button {
    border: 0 !important;
    border-bottom: 1px solid #ced4da !important;
    border-radius: 0 !important;

    &:last-child {
      border: 0 !important;
    }
  }
}

.backBtn {
  text-align: center;

  a {
    background-color: #e9e9ef;
    padding: 10px;
    border-radius: 5px;
    color: #000;
    display: inline-block;
    margin: 15px auto 0;
    box-shadow: 0 2px 6px 0 rgba(233, 233, 239, 0.5);
    font-weight: 400;
  }
}

.form-control[readonly=""] {
  background-color: #00000017;
}

.fixed {
  position: sticky;
  left: 0;
  min-width: 50px;
  max-width: 50px;

  &:nth-child(2) {
    left: 50px;
    min-width: 100px;
    max-width: 100px;
  }

  &:nth-child(3) {
    left: 150px;
    min-width: 110px;
    max-width: 100px;
  }

  &:nth-child(4) {
    left: 260px;
    min-width: 100px;
    max-width: 100px;
  }

  &:nth-child(5) {
    left: 360px;
    min-width: 100px;
    max-width: 100px;
    @media (max-width:1200px) {
      position: static;
    }
  }

  &:nth-child(6) {
    left: 460px;
    min-width: 100px;
    max-width: 100px;
    @media (max-width:1200px) {
      position: static;
    }
  }

  &:nth-child(7) {
    left: 560px;
    min-width: 100px;
    max-width: 100px;
    @media (max-width:1200px) {
      position: static;
    }
  }

  &:nth-child(8) {
    left: 660px;
    min-width: 100px;
    max-width: 100px;
    @media (max-width:1200px) {
      position: static;
    }
  }

  &:nth-child(9) {
    left: 760px;
    min-width: 100px;
    max-width: 100px;
    @media (max-width:1200px) {
      position: static;
    }
  }

  &:nth-child(10) {
    left: 860px;
    min-width: 100px;
    max-width: 100px;
     @media (max-width:1200px) {
      position: static;
    }
  }
}

.parkingNo {
  display: inline-block;
  margin-bottom: 10px;
}
strong {
  color: #000;
  font-size: 18px;
  font-weight: 800;
}

.floorNum {
  align-items: center;

  >* {
    max-width: fit-content;
    margin: 0 10px;
  }
}

.isaDisable {
  pointer-events: none;
  cursor: not-allowed;
  opacity: 0.5;
}

.cmn_styleselct {
  max-width: 140px;
}

.alphabet_style {
  display: flex;
  align-items: center;
}

.fontSize12{
  font-size: 12px !important;;
}
.margin5Top{
  margin-top: 5px !important;
}
.noBorder{
  border: none !important;
}

tr th {
  width: 100px !important;
}

.page-break_l  { display: block; page-break-before: always; }
.modal-height {
  height: 400px !important;
}
