.inspection-table-header-item {
  background-color: #0D568F !important;
color: white !important;
}

.modal-backdrop.show {
    opacity:0.18 !important  
}

.inspection-modal-header {
  background-color: #0D568F !important;
color: white !important;
}

.customDatePickerWidth, 
.customDatePickerWidth > div.react-datepicker-wrapper, 
.customDatePickerWidth > div > div.react-datepicker__input-container
.customDatePickerWidth > div > div.react-datepicker__input-container input {
   width: 100%;
}