.kye_fobPage .card {
  padding: 20px;
  .cardInner {
    margin-bottom: 20px;
    h2 {
      color: #0c518a;
      margin-bottom: 15px;
    }
    div:not(.fogSec) {
      background-color: #fff;
      padding: 20px;
      max-width: 30%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      input {
        max-width: 100px;
        text-align: center;
        font-size: 16px;
        border-radius: 10px;
        padding: 3px;
      }
      label {
        font-size: 16px;
      }
    }
  }
  .fogSec {
    div {
      &:first-child {
        max-width: 200px !important;
        flex: 0 0 200px;
        justify-content: flex-start !important;
      }
      &:nth-child(2) {
        max-width: 100%;
        input {
          // max-width: 500px !important;
          box-shadow: none !important;
          border: 1px solid #000;
        }
      }
      input {
        margin-right: 5px;
      }
    }
  }
  .formSubmit button {
    background-color: #0c518a;
    padding: 10px 30px;
    color: #fff;
    font-size: 16px;
    font-weight: 500;
    border-radius: 10px;
    border: 1px solid #0c518a;
    transition: 0.3s all linear;
    &:hover{
        background-color: transparent;
        color: #0c518a;
    }
  }
  .input200{
    width: 200px !important;
  }
}
