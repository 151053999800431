.blogList-page .selectProptySide {
  flex-wrap: wrap;
}

.blogList-page .selectProptySide select,
.blogList-page .selectProptySide .srchInput,
.blogList-page .selectProptySide .srchInput .form-control,
.blogList-page .selectProptySide .selectProptySide-btn,
.blogList-page .selectProptySide button {
  width: 100% !important;
}

.mainHdng.sideBarHdng .hdngAviableRight {
  padding-bottom: 0;
  margin-top: 10px;
}

.d-inline {
  display: inline !important;
}

.mainHdng .hdngRigtTitle {
  font-size: 16px;
}

.cardPropertyBox .cartBlkImg img {
  width: 100%;
  object-fit: cover;
  min-height: 100%;
}

.cartBlkImg {
  height: 220px;
  width: 100%;
}

.blogList-text {
  padding: 16px;
  height: 100%;
}

.blogList-text h3 {
  margin-bottom: 10px !important;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box !important;
  /* -webkit-line-clamp: 2; */
  -webkit-box-orient: vertical;
  white-space: normal;
  /* min-height: 55px; */
}

.blogList-text .blogList-desc {
  height: 119px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box !important;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  margin-bottom: 50px;
}

.blogListBox .cartBlkImg {
  box-shadow: 0px 0px 21px 0px rgba(0, 0, 0, 0.12);
  border-radius: 5px;
}

.blogListBox .cartBlkImg img {
  height: 270px;
  border-radius: 5px;
}

.blogListBox h3 {
  padding: 50px 0px;
}

.pd60 {
  padding: 60px 0;
}

.wrapper {
  float: left;
  width: 100%;
}

body {
  font-family: "Calibri" !important;
  color: #888888;
}
