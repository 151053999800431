.kpiList_page {
  .kpiTabs {
    background-color: #0c518a;
    padding-bottom: 0 !important;
    padding-left: 50px !important;
    padding-right: 50px !important;
    justify-content: flex-start;
    position: sticky;
    li {
      margin-right: 10px;
      .nav-link {
        color: #fff;
        &.active {
          color: #0c518a !important;
        }
      }
    }
  }
}
